#root {
  min-height: 100vh;
}

.app {
  color: #e0e0e0;
  font-family: Arial, sans-serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0px 0 0;
}

.container h2 {
  color: #dcebff;
}

.mainContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-top: 6%;
}

.matchesSection {
  flex: 3;
}

.betslipSection {
  flex: 1;
}

.match-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.team-vs-team {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.team {
  text-align: center;
}

.team img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 5px;
}

#betslip {
  position: fixed;
  bottom: 20px;
  right: calc((100% - 1200px) / 2 + 0px);
  width: calc(1200px / 4 - 5px);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.336);
  backdrop-filter: blur(15px);
  border-radius: 10px;
  padding: 20px;
  z-index: 10;
}

#betslip h2 {
  margin-top: 0;
}

.betInput {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.betInput input {
  margin-right: 10px;
  width: 100%;
  padding: 10px 5px;
  border-radius: 10px;
  background-color: #ffffff1a;
  color: #e0e0e0;
}

#placeBet {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 0;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.placeBet {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 0;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.error {
  border-color: rgb(239, 68, 68);
}

.error:focus {
  border-color: rgb(239, 68, 68);
  box-shadow: 0 0 0 1px rgb(239, 68, 68);
}

.disabledButton {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  background-color: rgba(255, 255, 255, 0.048);
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 0;
  border-radius: 10px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  cursor: not-allowed;
}

.disabledButton:hover {
  background-color: rgba(255, 255, 255, 0.048);
}

.placeBet:hover {
  background-color: #f4af0094;
}

#placeBet:hover {
  background-color: #f4af0094;
}

.greyedOut {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.greyedOut:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

#myBets {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: calc(90px + 60px + 20px);
}

.bet-item {
  border-bottom: 1px solid #f4af0094;
  padding: 10px 0;
}

.bet-item:last-child {
  border-bottom: none;
}

.footer {
  background-color: rgba(255, 255, 255, 0.1);
  height: auto;
  color: #e0e0e0;
  text-align: center;
  padding: 20px 0;
  margin-top: auto;
}

/* Styles pour les tablettes et mobiles (< 1024px) */
@media screen and (max-width: 1024px) {
  .mainContent {
    flex-direction: column;
  }

  .matchesSection {
    width: 100%;
  }

  .betslipSection {
    width: 100%;
  }

  #betslip {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 10px 10px 0 0;
  }

  .betInput > div {
    width: 100%;
  }

  .container {
    padding: 20px 16px 0;
  }

  #myBets {
    margin-bottom: 200px; /* Espace pour le betslip fixe */
  }
}

/* Styles pour mobiles (< 768px) */
@media screen and (max-width: 768px) {
  .container {
    padding: 16px 12px 0;
  }

  .matchCard {
    padding: 12px;
  }

  .matchHeader {
    flex-direction: column;
    gap: 8px;
  }

  .matchTitle, .matchTitle2 {
    text-align: center;
    width: 100%;
  }

  .teamVsTeam {
    justify-content: space-around;
  }

  .team {
    flex: 0 1 auto;
  }

  .odds {
    flex-direction: column;
    gap: 8px;
  }

  .odds button {
    width: 100%;
  }

  .betInput > div {
    width: 100%;
  }

  .matchesSection {
    margin-top: 5%;
  }

  /* Ajustements pour ActiveBets */
  .bet-item {
    flex-direction: column;
  }

  .bet-item > div {
    width: 100%;
    margin-bottom: 8px;
  }

  /* Ajustements pour SelectedBets */
  .selectedBet {
    flex-direction: column;
  }
}

/* Styles pour très petits écrans (< 360px) */
@media screen and (max-width: 360px) {
  .container {
    padding: 12px 8px 0;
  }

  .team img {
    width: 32px;
    height: 32px;
  }

  .matchTitle, .matchTitle2 {
    font-size: 0.9em;
  }

  .odds button {
    padding: 8px 12px;
  }
}

/* Ajustements de l'interface de streaming */
@media screen and (max-width: 1024px) {
  .liveStreamContainer {
    height: 200px;
  }

  .liveStreamContainer.expanded {
    height: 200px;
  }
}

@media (min-width: 1024px) and (max-width: 1450px) {
  #betslip {
    margin-left: 125px;
    right: 22px;
    /* position: relative; */
    bottom: 12%;
    width: 21.5%;
  }
  .container {
    margin-left: 125px;
    margin-right: 22px;
  }
}

@media (min-width: 821px) and (max-width: 1024px) {
  #betslip {
    margin-left: 125px;
    margin-right: 35px;
    width: auto;
  }
  .container {
    margin-left: 110px;
    margin-right: 20px;
    margin-top: 5%;
  }
}
