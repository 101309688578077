.AdminPanel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 120px;
}

.readonly-input {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.coach-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.team-size-info {
  background-color: #ffffff63;
  padding: 10px;
  border-radius: 4px;
}

.admin-tournament-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 400px;
  height: 600px;
  margin: auto;
  margin-top: 150px;
  padding: 20px;
  background-color:#e0e0e0c5;
  border-radius: 10px;
  box-shadow: 0 0 10px 1px #0000007a;
  transition: transform 0.3s ease, box-shadow 0.3s ease; 
}

.admin-tournament-form:hover {
  transform: scale(1.07);
  box-shadow: 0 0 15px 3px #000000a0;
}

.match-list {
  max-height: 400px;
  overflow-y: auto; 
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  overflow-x: hidden;
}

.match-list-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.button-match-list1 {
  width: 100px;
  background-color: rgb(0, 110, 255) !important;
}

.button-match-list2 {
  width: auto;
  height: 50px;
  background-color: rgb(0, 110, 255) !important;
}

.admin-tournament-form input,
.admin-tournament-form select,
.admin-tournament-form button {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.admin-tournament-form button {
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.admin-tournament-form button:hover {
  background-color: #45a049;
}
