/* Pour les navigateurs basés sur WebKit (Chrome, Safari, Edge) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Supprime l'apparence par défaut */
  margin: 0; /* Supprime les marges */
}

/* Pour Firefox */
input[type="number"] {
  -moz-appearance: textfield; /* Supprime les flèches */
}

.parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

h2 {
  display: flex;
  justify-content: center;
  font-size: 16px;
}

.configInfo {
  background: white;
  padding: 1rem;
  border-radius: 6px;
  margin-top: 1rem;
}

.configInfo p {
  margin: 0.5rem 0;
}

.configInfo strong {
  color: #2c3e50;
}

.infoGrid {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 6px;
  margin-bottom: 2rem;
}

.secondaryButton {
  background: #6c757d;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.secondaryButton:hover {
  background: #5a6268;
}

.info {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.calculatedRewards {
  margin-top: 1rem;
  padding: 0.5rem;
  background: #e9ecef;
  border-radius: 4px;
  font-weight: bold;
}

.adminPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 400px;
  height: 600px;
  margin: auto;
  margin-top: 150px;
  padding: 20px;
  background-color: #e0e0e0c5;
  border-radius: 10px;
  box-shadow: 0 0 10px 1px #0000007a;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.adminPanel:hover {
  transform: scale(1.07);
  box-shadow: 0 0 15px 3px #000000a0;
}

.infoGrid {
  margin: 1.5rem 0;
  padding: 1rem;
  background: white;
  border-radius: 6px;
}

.rewardSetup {
  margin-top: 2rem;
}

.inputGroup {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.button {
  padding: 0.5rem 1rem;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.button:hover:not(:disabled) {
  background: #0056b3;
}

#button {
  background-color: #00000054;
  color: #f4b000;
  border: none;
  padding: 6px 20px;
  margin-left: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

#button:hover,
#buttonClaim:hover {
  background-color: #00000079;
}

.inputRow {
  position: relative;
  width: 100%;
}

.maxButton {
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 2px;
  color: #f4b000;
  border: none;
  padding: 2px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.maxButton:hover {
  color: #f4af00b7;
}

#buttonClaim {
  display: flex;
  justify-content: center;
  justify-items: center;
  margin: 0 auto;
  background-color: #00000054;
  color: #f4b000;
  border: none;
  padding: 6px 30px;
  margin-top: 0px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

#input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 0 5px;
  background-color: #00000054;
  color: #ffffff7a;
}

#input:hover {
  background-color: #00000079;
}

#input:focus {
  outline: none;
}

.stake {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0px;
}

.unstake {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0px;
}

.balanceInfo,
.rewardsInfo {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: #666;
}

.error {
  color: #dc3545;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.stakingStats {
  border-radius: 8px;
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
}

.statItem {
  text-align: center;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.247);
  border-radius: 6px;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.048);
}

.statItem h3 {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: #d1d8cd
}

.statItem p {
  font-size: 1.2rem;
  font-weight: bold;
  color: #acb1a98f;
  margin: 0;
}

.container {
  display: grid;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 30px 20px 30px 50px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0px #000000b4;
  backdrop-filter: blur(3px);
}

.actionsGrid {
  display: grid;
  padding: 0px 2rem 0px 0;
}

.stakingInterface {
  display: grid;
  grid-template-columns: 4fr 1fr 4fr;
}

.divider {
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  margin: 2rem 0 0.75rem;
  width: 100%;
  padding-top: 0.5rem;
}

@media (max-width: 768px) {
  .container {min-width: 100vw;}
  .stakingInterface {
    grid-template-columns: 1fr;
  }
  .actionsGrid {
    margin-top: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1135px) {
  .container {
    min-width: 60vw;
    margin-left: 0px;
    max-width: 600px;
  }
  .stakingInterface {
    grid-template-columns: 1fr;
  }
  .actionsGrid {
    margin-top: 20px;
  }
}

