/* Modal base */
.customTeamModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 48;
  padding: 2rem;
}

/* Modal content wrapper */
.customTeamModal .modalContent {
  background: var(--dark, #1D1F24);
  background-color: #ffffff1a;
  backdrop-filter: blur(5px);
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.233);
  width: 100%;
  max-width: 1200px;
  overflow-y: auto;
  max-height: 80vh;
  position: relative;
  animation: modalFadeIn 0.3s ease-out;
}

.customTeamModal .modalContent2 {
  background: var(--dark, #1D1F24);
  background-color: #ffffff1a;
  backdrop-filter: blur(5px);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.233);
  width: 100%;
  max-width: 800px;
  overflow-y: auto;
  max-height: 90vh;
  position: relative;
  animation: modalFadeIn 0.3s ease-out;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Team dashboard content */
.teamDashboard {
  color: white;
  font-family: 'Space Grotesk', sans-serif;
}

.teamBanner {
  height: 300px;
  border-radius: 20px 20px 0 0;
  background: linear-gradient(135deg, #F4B000, #FF6B6B);
  position: relative;
  overflow: hidden;
}

.teamBanner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
}

.teamBannerContent {
  position: absolute;
  bottom: 0rem;
  left: 2rem;
  display: flex;
  align-items: flex-end;
  gap: 2rem;
}

.imageControls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 8px;
  z-index: 10;
  transition: all 0.2s ease;
}

.imageControls:hover {
  transform: scale(1.1);
}

.cancelUploadButton {
  background-color: rgba(255, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cancelUploadButton:hover {
  background-color: rgba(255, 0, 0, 0.8);
  transform: scale(1.1);
}

.imageUploadButton span {
  margin-left: 8px;
  font-size: 14px;
}

.teamDashboardLogo {
  width: 120px;
  height: 120px;
  background: var(--dark);
  border: 4px solid var(--primary);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -30px;
}

.teamDashboardInfo {
  padding-bottom: 1rem;
  color: white;
}

.teamDashboardName {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.teamDashboardMeta {
  display: flex;
  gap: 2rem;
  opacity: 0.9;
}

.teamContent {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 2rem;
  padding: 2rem;
  max-width: 100%;
  overflow: hidden;
}

/* Rest of your styles remain the same */
.teamMain {
  display: grid;
  grid-template-columns: 10fr 1fr 7fr;
  background: rgba(255,255,255,0.05);
  border-radius: 20px;
  padding: 2rem;
}

.teamDescription {
  max-width: 100%;
  width: 100%;
}

.teamDescription h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.teamDescription p {
  background: rgba(255,255,255,0.05);
  padding: 10px;
  border-radius: 20px;
  line-height: 1.6;
  opacity: 0.8;
  max-width: 800px;
  word-break: break-word;
  overflow-wrap: break-word;
  margin: 0 10px 0 0;
}

.textarea {
  width: 100%;
  max-width: 800px;
  background: rgba(255,255,255,0.05);
  border: none;
  border-radius: 10px;
  color: white;
  resize: vertical;
  min-height: 240px;
  max-height: 400px;
  line-height: 1.6;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-y: auto;
  font-size: 1rem;
}

.textarea:focus {
  outline: none;
  background: rgba(255,255,255,0.08);
}

.teamMembersList {
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); */
  max-height: 300px;
}

.teamMembersList h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  grid-column: 1 / -1;
  font-weight: 600;
}

.teamMemberCard {
  background: rgba(255,255,255,0.05);
  border-radius: 15px;
  padding: 1rem;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: transform 0.3s, background-color 0.3s;
}

.teamMemberCard:hover {
  cursor: pointer;
  background: rgba(255,255,255,0.08);
  transform: translateY(-2px);
}

.memberInitial {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gradient);
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
}

.memberInfo2 {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: flex-end;
  margin-left: auto;
  padding-right: 0.5rem;
  padding-left: 5rem;
}

.memberInfo2 span:first-child {
  font-size: 1.1rem;
  font-weight: 500;
  text-align: right;
}

.memberInfo2 span:last-child {
  font-size: 0.9rem;
  opacity: 0.7;
  color: var(--primary);
  text-align: right;
}

.memberInfo h3 {
  font-size: 1.1rem;
  margin-bottom: 0.25rem;
}

.memberInfo p {
  opacity: 0.7;
  font-size: 0.9rem;
  color: var(--primary);
}

.teamSidebar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,0.05);
  border-radius: 20px;
  padding: 2rem;
}

.teamActions {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.teamActionBtn {
  background: var(--gradient);
  border: none;
  color: white;
  padding: 1rem;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  transition: opacity 0.3s;
  font-family: 'Space Grotesk', sans-serif;
}

.teamActionBtn:hover {
  opacity: 0.9;
}

.deleteTeamBtn {
  background: linear-gradient(135deg, #ff4444, #ff0000);
  margin-top: auto;
}

.inviteMemberForm {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
}

.invalid {
  border-color: red;
}

.inviteMemberForm input {
  background: rgba(255,255,255,0.05);
  width: 80%;
  border: none;
  padding: 0.8rem;
  border-radius: 10px;
  color: white;
  font-family: 'Space Grotesk', sans-serif;
}

.inviteMemberForm input:focus,
.inviteMemberForm select:focus {
  outline: none;
  background: rgba(255,255,255,0.08);
}

.membersContainer {
  padding: 0 0.25rem;
  margin: 20px 0;
  width: 100%;
}

.selectMembersList {
  list-style: none;
  max-height: 320px;
  overflow-y: auto;
  color: white;
  width: 100%;
  padding: 0.5rem;
}

.selectMember {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  margin-bottom: 8px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 17px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.selectMember:hover {
  background: rgba(255, 255, 255, 0.178);
  transform: translateY(-2px);
}

.selected {
  background: rgba(21, 255, 0, 0.144);
  transform: translateY(-3px);
  box-shadow: 0 5px 5px #0000006e;
}

.selected:hover {
  background: rgba(21, 255, 0, 0.144);
  transform: translateY(-2px);
}

.memberImageContainer {
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  background: var(--gradient);
}

.memberImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.leaveButton {
  background: none;
  border: none;
  color: #ff4444;
  cursor: pointer;
  display: flex;
  margin-left: auto;
  padding: 0.5rem;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.leaveButton:hover {
  background: rgba(255,68,68,0.1);
}

.createTeamModal {
  background: rgba(255,255,255,0.05);
  padding: 2rem;
  border-radius: 20px;
  grid-column: 1 / -1;
}

.input {
  width: 100%;
  background: rgba(255,255,255,0.05);
  border: none;
  padding: 1rem;
  border-radius: 10px;
  color: white;
  font-family: 'Space Grotesk', sans-serif;
  margin-bottom: 1rem;
}

.input:focus {
  outline: none;
  background: rgba(255,255,255,0.08);
}

.errorMessage {
  color: #ff4444;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.uploadControls {
  display: flex;
  gap: 8px;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  padding: 8px;
  border-radius: 8px;
}

.confirmButton {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.confirmButton:hover {
  background-color: #45a049;
}

.dragInstructions {
  color: white;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.7);
  padding: 8px 16px;
  border-radius: 4px;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.imageControls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  z-index: 10;
}

.registerTeamButton {
  width: 100%;
  padding: 1rem;
  background: var(--gradient);
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.3s;
  margin-top: 1rem;
}

.registerTeamButton:hover {
  opacity: 0.9;
}

.registerTeamButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .customTeamModal {
    padding: 1rem;
  }

  .modalContent2 {
    padding: 15px;
  }

  .selectMember {
    padding: 0.75rem;
  }

  .memberInitial {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }

  .memberInfo2 span:first-child {
    font-size: 1rem;
  }

  .memberInfo2 span:last-child {
    font-size: 0.8rem;
  }

  .teamContent {
    grid-template-columns: 1fr;
  }

  .teamBannerContent {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .teamDashboardLogo {
    margin-bottom: 0;
  }

  .teamDashboardName {
    font-size: 2rem;
  }

  .teamDashboardMeta {
    flex-wrap: wrap;
    gap: 1rem;
  }
}

/* Scrollbar styles for the modal */
.customTeamModal :global(.modal-content)::-webkit-scrollbar {
  width: 8px;
}

.customTeamModal :global(.modal-content)::-webkit-scrollbar-track {
  background: rgba(255,255,255,0.05);
}

.customTeamModal :global(.modal-content)::-webkit-scrollbar-thumb {
  background: rgba(244,176,0,0.3);
  border-radius: 4px;
}

.customTeamModal :global(.modal-content)::-webkit-scrollbar-thumb:hover {
  background: rgba(244,176,0,0.5);
}

@media (max-width: 768px) {
  .membersContainer {
    padding: 0 1rem;
  }
  
  .selectMember {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .membersContainer {
    padding: 0 0.5rem;
  }
}

/* Tablettes (768px à 1024px) */
@media (max-width: 1024px) {
  .teamContent {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 1rem;
  }

  .teamMain {
    grid-template-columns: 1fr;
    padding: 1.5rem;
  }

  .teamBanner {
    height: 250px;
  }

  .teamBannerContent {
    padding: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .teamDashboardLogo {
    width: 100px;
    height: 100px;
    margin-bottom: 0;
  }

  .teamDashboardInfo {
    margin-left: 0;
    padding-bottom: 0;
  }

  .teamDashboardName {
    font-size: 2rem;
  }

  .teamDashboardMeta {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .teamSidebar {
    margin-top: 1rem;
  }

  .teamActionBtnChat {
    display: none;
  }

  .inviteMemberForm {
    display: none;
  }

  .inviteMemberForm input {
    width: 100%;
  }

  .imageControls {
    flex-direction: column;
    top: 5px;
    right: 5px;
  }
}

/* Mobile (jusqu'à 767px) */
@media (max-width: 767px) {
  .customTeamModal {
    padding: 0.5rem;
  }

  .modalContent {
    border-radius: 15px;
    height: 70%;
  }

  .teamBanner {
    height: 220px;
    border-radius: 15px 15px 0 0;
  }

  .teamDashboardName {
    font-size: 1.5rem;
  }

  .teamContent {
    padding: 0.5rem;
  }

  .teamMain {
    padding: 1rem;
  }

  .teamDescription h2,
  .teamMembersList h2 {
    font-size: 1.25rem;
  }

  .teamMemberCard {
    padding: 0.75rem;
    margin-bottom: 0.5rem;
  }

  .memberInitial {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }

  .memberInfo h3 {
    font-size: 1rem;
  }

  .memberInfo p {
    font-size: 0.8rem;
  }

  .teamActionBtn {
    padding: 0.75rem;
    font-size: 0.9rem;
  }

  .teamDashboardMeta {
    font-size: 0.7rem;
    gap: 0.5rem;
  }

  .teamDashboardMeta span:not(:last-child)::after {
    margin: 0 0.25rem;
  }

  .imageUploadButton span {
    display: none;
  }

  .inviteMemberForm input {
    padding: 0.6rem;
    font-size: 0.9rem;
  }

  .imageControls {
    flex-direction: column;
    width: 10%;
    height: 10%;
    top: 10px;
  }

  .teamBannerContent {
    z-index: 1000;
    margin-left: -30px;
    padding-top:0;
  }
}

/* Très petits écrans (jusqu'à 375px) */
@media (max-width: 375px) {
  .teamDashboardName {
    font-size: 1.25rem;
  }

  .teamBanner {
    height: 180px;
  }

  .teamDashboardLogo {
    width: 80px;
    height: 80px;
  }

  .teamMemberCard {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
  }

  .memberInfo {
    text-align: center;
  }

  .teamDashboardMeta {
    flex-direction: column;
    align-items: flex-start;
  }

  .teamDashboardMeta span:not(:last-child)::after {
    display: none;
  }
}

/* Orientation paysage sur mobile */
@media (max-height: 500px) and (orientation: landscape) {
  .teamBanner {
    height: 150px;
  }

  .teamDashboardLogo {
    width: 70px;
    height: 70px;
  }

  .teamContent {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
  }

  .modalContent {
    max-height: 90vh;
  }
}

@media (max-width: 1100px) {
  .teamMain {
    grid-template-columns: 1fr;
  }
  .teamMembersList h2 {
    margin-top: 1.5rem;
  }
}

/* Tablettes (768px à 1024px) */
@media (max-width: 1024px) {
  .teamDescription {
    min-height: 250px;
    width: 100%;
  }

  .teamDescription p {
    padding-right: 1rem;
  }
}

/* Très petits écrans (jusqu'à 375px) */
@media (max-width: 375px) {
  .teamDescription {
    margin-bottom: 1rem;
  }

  .teamDescription h2 {
    font-size: 1.1rem;
  }

  .teamDescription p {
    font-size: 0.9rem;
    max-height: 150px;
    line-height: 1.4;
  }

  .textarea {
    min-height: 100px;
    max-height: 200px;
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}

/* Mode paysage sur mobile */
@media (max-height: 500px) and (orientation: landscape) {
  .teamDescription {
    min-height: auto;
  }

  .teamDescription p {
    max-height: 120px;
  }

  .textarea {
    min-height: 80px;
    max-height: 150px;
  }
}