@keyframes slideUp {
  0% {
    height: 0;
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    height: 400px;
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  0% {
    height: 400px;
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    height: 0;
    transform: translateY(50px);
    opacity: 0;
  }
}

@keyframes cardEntry {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.matchCardsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.matchCard {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  opacity: 0;
  animation: cardEntry 0.4s ease forwards;
}

.matchCard:nth-child(1) { animation-delay: 0s; }
.matchCard:nth-child(2) { animation-delay: 0.1s; }
.matchCard:nth-child(3) { animation-delay: 0.2s; }
.matchCard:nth-child(4) { animation-delay: 0.3s; }
.matchCard:nth-child(5) { animation-delay: 0.4s; }

.streamExpanded {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.liveStreamContainer {
  height: 0;
  opacity: 0;
  overflow: hidden;
  background: #000000;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: height 0.4s ease;
}

.liveStreamContainer.expanded {
  animation: slideUp 0.4s ease forwards;
}

.liveStreamContainer.closing {
  animation: slideDown 0.4s ease forwards;
  min-height: 0;
  height: 400px;
}

.liveStream {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.liveStream iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.matchHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 15px;
}

.matchTitle {
  font-size: medium;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.matchTime {
  font-size: 14px;
  color: #f4b000;
}

.teamVsTeam {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.team {
  text-align: center;
}

.team img {
  width: 50px;
  height: auto;
  margin-bottom: 5px;
}

.vs {
  font-size: large;
  font-weight: bold;
}

.odds {
  display: flex;
  justify-content: space-between;
}

.oddButton {
  background-color: rgba(255, 255, 255, 0.1);
  color: #e0e0e0;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.oddButton:hover {
  background-color: #f4af0094;
}

.oddButton.selected {
  background-color: #f4af0094;
}

.matchHeader {
  position: relative;
}

.runningIndicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  margin-left: 5px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.titleHead {
  display: flex;
  align-items: baseline;
}

.matchTitle2 {
  font-size: medium;
  color: #666d77;
}

.matchCard.betExists {
  background-color: rgba(255, 255, 255, 0.021);
  opacity: 0.7;
}

.oddButton.betExists {
  background-color: #88888831;
  cursor: not-allowed;
}

.oddButton.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.betExistsMessage {
  color: #000000;
  font-weight: bold;
  margin-top: 5px;
}

/* Styles pour mobiles (< 768px) */
@media screen and (max-width: 734px) {
 .liveStreamContainer {
    height: 314px !important;
 }
}

@media screen and (max-width: 581px) {
  .liveStreamContainer {
     height: 238px !important;
  }
 }