#chatButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0px 0px 10px #ffffff21;
  backdrop-filter: blur(12px);
  border: none;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
}

.textareaWrapper {
  position: relative;
  flex: 1;
}

.mentionSuggestions {
  position: absolute;
  bottom: 100%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: 200px;
  max-height: 200px;
  overflow-y: auto;
  display: none;
}

.mentionSuggestions.show {
  display: block;
}

.mentionItem {
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.2s;
}

.mentionItem:hover {
  background-color: #f5f5f5;
}

.mentionDisabled {
  padding: 8px 12px;
  color: #999;
  font-style: italic;
  font-size: 0.9em;
}

/* Pour que le textarea prenne toute la largeur disponible */
.messageTextarea {
  width: 100%;
  min-height: 40px;
  resize: vertical;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.everyoneMessage {
  background-color: rgba(244, 176, 0, 0.15);
  border-left: 4px solid #F4B000;
}

.everyoneMessage .messageContent {
  color: #F4B000;
  font-weight: 500;
}

#chatButton:hover {
  transform: scale(1.05);
}

@keyframes modalShow {
  from {
    opacity: 0;
    transform: translate(calc(50vw - 35px), calc(50vh - 35px)) scale(0);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.205);
  backdrop-filter: blur(12px);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.233);
  display: none;
  position: fixed;
  font-size: 13px;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 49;
  opacity: 0;
}

.modal.open {
  display: block;
  animation: fadeIn 0.2s ease-out forwards;
}

html:has(.modal.open) {
  overflow: hidden;
}

.modalContent {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 900px;
  border-radius: 10px;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.24);
  transform-origin: bottom right;
}

.modal.open .modalContent {
  animation: modalShow 0.5s cubic-bezier(0.34, 1.2, 0.64, 1) forwards;
}

.chatContainer {
  display: flex;
  height: 500px;
}

.sidebar {
  width: 250px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
}

.chatSelector {
  display: flex;
  flex-direction: column;
}

.chatButton {
  background-color: rgba(0, 0, 0, 0.274);
  border: none;
  color: #dcddde;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.chatButton:hover {
  background-color: #3a3a3a;
}

.chatButton.active {
  background-color: #5f5f5f;
}

.mainContent {
  flex-grow: 1;
  max-width: 650px;
  display: flex;
  flex-direction: column;
}

.chatWindow {
  position: relative;
  flex-grow: 1;
  padding: 20px 20px 0px;
  border-top-right-radius: 10px;
  color: #dcddde;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.274);
  box-shadow: inset 0 25px 40px -5px rgba(0, 0, 0, 0.534);
  scroll-behavior: smooth;
  transition: scroll 50ms ease-out;
}

.chatWindow::-webkit-scrollbar {
  width: 0.1px;
}

.chatInput {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-bottom-right-radius: 10px;
  background-color: rgba(255, 255, 255, 0.274);
  z-index: 10;
}

.messageActions {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.votingPanel {
  position: absolute;
  bottom: 74px;
  left: 0;
  right: 0;
  background: #444444;
  font-weight: 700;
  padding: 7px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  display: flex;
  justify-content: space-around;
  animation: slideInUp 0.4s linear forwards;
  z-index: 9;
}

.chatWindow.withVotingPanel {
  margin-bottom: 45px;
}

.votingPanel.exiting {
  animation: slideOutDown 0.4s linear forwards;
}

.votingPanel button {
  background: #5f5f5f;
  color: white;
  border: none;
  padding: 10px 6em;
  border-radius: 5px;
  cursor: pointer;
}

#voteButton {
  background-color: #797979;
  border: none;
  color: white;
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.messageTextarea {
  flex-grow: 1;
  background-color: #4646462f;
  border: none;
  border-radius: 5px;
  color: #dcddde;
  padding: 10px;
  resize: none;
  min-height: 40px;
  max-height: 40px;
  overflow-y: auto;
  line-height: 1.5;
  font-family: inherit;
  font-size: inherit;
}

/* Style pour la scrollbar du textarea */
.messageTextarea::-webkit-scrollbar {
  width: 6px;
}

.messageTextarea::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.messageTextarea::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.messageTextarea:focus {
  outline: none;
  background-color: #0000002f;
}

#sendButton {
  background-color: #4646462f;
  border: none;
  border: none;
  color: white;
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
}

#sendButton:focus,
.messageTextarea:focus,
#sendButton:hover,
.messageTextarea:hover {
  outline: none;
  background-color: #0000002f;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff13;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.185);
}

.message .username {
  display: flex;
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
  color: #ffffff5e;
  justify-content: start;
}

.message .timestamp {
  display: flex;
  font-size: 0.8em;
  color: #72767d;
  justify-content: end;
}

.messageContent {
  color: #0e0d0d;
  display: block; /* Changé de flex à block */
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 1.5;
}

.gridMessage {
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  display: grid;
  grid-template-columns: auto auto 30px;
  align-items: center;
  gap: 8px;
}

.messageContainer {
  display: flex;
  gap: 12px;
  position: relative;
}

.avatarIcon {
  width: 30px;
  height: 30px;
  padding: 6px;
  color: #ffffff5e;
  background-color: #0e0d0d49;
  border-radius: 100px;
}

.avatarContainer {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  transition: transform 0.2s, opacity 0.2s;
  cursor: pointer;
}

.message.replyToMe {
  background-color: rgba(121, 184, 255, 0.37);
}

.message.replyToMe .replyPreview {
  background-color: rgba(0, 0, 0, 0.25);
  border-left-color: rgba(121, 184, 255, 0.4);
}

.message {
  position: relative;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff13;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.185);
  transition: background-color 0.3s ease;
}

.message.highlighted {
  background-color: rgba(255, 255, 255, 0.25);
}

.replyPreview {
  margin-bottom: 8px;
  padding: 6px 8px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  cursor: pointer;
  border-left: 2px solid #ffffff3d;
  max-width: 95%;
  transition: all 0.2s ease;
}

.replyPreview:hover {
  background-color: rgba(0, 0, 0, 0.3);
  border-left-color: #ffffff6e;
}

.replyHeader {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 2px;
}

.replyIcon {
  font-size: 12px;
  color: #ffffff6e;
}

.repliedToUser {
  font-size: 0.85em;
  color: #ffffffcc;
  font-weight: 500;
}

.repliedContent {
  font-size: 0.85em;
  color: #ffffff99;
  margin-left: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.replyButton {
  opacity: 0;
  color: #ffffff5e;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
}

.messageContainer:hover .replyButton {
  opacity: 1;
}

.replyButton:hover {
  color: #ffffff;
  transform: scale(1.1);
}

/* Style pour la prévisualisation de réponse lors de l'écriture */
.replyingToPreview {
  background: rgba(0, 0, 0, 0.2);
  padding: 8px 12px;
  margin-bottom: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-left: 2px solid #ffffff3d;
  width: 100%;
}

.replyingToPreview span {
  color: #ffffff99;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 6px;
}

.cancelReply {
  background: none;
  border: none;
  color: #ffffff5e;
  cursor: pointer;
  padding: 4px;
  font-size: 16px;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancelReply:hover {
  color: #ffffff;
}

.avatarContainer:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

.avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  transition: filter 0.2s;
}

.messageWrapper {
  flex-grow: 1;
  min-width: 0;
}

/* Ajustement pour les messages système */
.systemMessage .messageContainer {
  padding-left: 44px; /* 32px (largeur avatar) + 12px (gap) */
}

.scrollButton {
  position: sticky;
  bottom: 10px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.274);
  color: #dcddde;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.scrollButton:hover {
  background-color: #0000006e;
}

@keyframes slideInUp {
  from {
    transform: translateY(10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOutDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(10%);
    opacity: 0;
  }
}

/* Ajoutez ceci à votre ChatButton.module.css */

.message.systemMessage {
  background-color: rgba(211, 234, 255, 0.253) !important;
  font-family: monospace !important;
  white-space: pre-wrap !important;
  word-wrap: break-word;
}

.messageContent.systemContent {
  color: #dcddde !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  width: 100%;
}

/* Assurez-vous que ces styles sont bien importés et appliqués */
.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff13;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.185);
}

.messageContent {
  color: #0e0d0d;
  display: flex;
  justify-content: flex-start;
}

/* Responsive Design */
@media screen and (max-width: 1200px) {
  .modalContent {
    width: 95%;
  }
}

/* Tablet and smaller desktops */
@media screen and (max-width: 1024px) {
  .modalContent,
  #chatButton {
    display: none;
  }
}
