@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Minion Pro';
  src: local('Minion Pro'),
       url('./assets/fonts/minionpro-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/minionpro-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF; /* Latin glyphs only */
}

@font-face {
  font-family: 'AvantGarde';
  src: local('AvantGarde'),
       url('./assets/fonts/itcavantgardepro-bold-webfont.woff2') format('woff2'),
       url('./assets/fonts/itcavantgardepro-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'Owners';
  src: local('Owners'),
       url('./assets/fonts/fonnts.com-owners_xxwide_bold-webfont.woff2') format('woff2'),
       url('./assets/fonts/fonnts.com-owners_xxwide_bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

*::-webkit-scrollbar {
  width: 0px;
  background-color: #000000c5;
  height: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

:root {
  --primary: #F4B000;
  --dark: #1D1F24;
  --gradient: linear-gradient(135deg, var(--primary), #ff6b6b);
}

html, body {
  margin: 0;
  font-family: 'AvantGarde', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.618;
}

body {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
}

html {
  overflow-x: hidden;
  width: 100%;
  max-width: 100vw;
}

.App-main h2 {
  font-family: 'Owners', sans-serif;
  font-size: 1.6rem;
  font-weight: bold;
  margin: 0;
  text-shadow: #00000085 0px 0px 20px;
  color: #ffffff;
}

.App-main h1 {
  font-family: 'Owners', sans-serif;
  font-size: 4rem;
  font-weight: bold;
  margin: 0;
  color: #ffffff;
  text-shadow: #00000085 0px 0px 20px;
  line-height: 0.7 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
