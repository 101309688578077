.slideEnter {
  transform: translateX(100%);
  opacity: 0;
}

.slideEnterActive {
  transform: translateX(0%);
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.slideExit {
  transform: translateX(0%);
  opacity: 1;
}

.slideExitActive {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 300ms ease-in-out;
}

.base {
  max-width: 100%;
  margin: 0 auto;
  padding: 110px 130px 20px;
  z-index: 5;
}

.container {
  display: grid;
  grid-template-columns: 9fr 3fr;
  gap: 0px 20px;
}

.container2 {
  display: grid;
  grid-template-columns: 9fr 3fr;
  gap: 20px;
}

.esportDiv {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.233);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px);
}

.ladder {
  width: auto;  /* Remplacez cette ligne par : */
  width: 100%;
  min-width: 0; /* Crucial pour empêcher le dépassement de la grille */
}

.register-tournament {
  max-width: 500px;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.register-tournament h2 {
  margin-bottom: 20px;
}

.register-tournament input {
  display: block;
  width: calc(100% - 20px);
  margin: 10px auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.register-tournament button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.register-tournament button:hover {
  background-color: #45a049;
}

/* Styles for the registration form */
.registration-form {
  margin-top: 20px;
}

.registration-form h3 {
  margin-bottom: 10px;
}

.registration-form input {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: rgb(37, 37, 37);
  color: white;
}

.registration-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ff4c4c;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.registration-form button:hover {
  background-color: #ff6666;
}

@media (min-width: 820px) and (max-width: 1200px) {
  .base {
    padding: 110px 60px 20px;
    margin-left: 65px;
  }

  .container,
  .container2 {
    grid-template-columns: 1fr;
  }

  .registeredStrip {
    grid-template-columns: 1fr;
    margin: 5px 0;
    padding: 0;
  }
}

@media screen and (max-width: 1200px) {
  .base {
    padding: 120px 60px 20px;
  }

  .container,
  .container2 {
    grid-template-columns: 1fr;
  }

  .registeredStrip {
    grid-template-columns: 1fr;
    margin: 5px 0;
    padding: 0;
  }
}

@media screen and (min-width: 640px) and (max-width: 820px) {
  .base {
    padding: 90px 20px 20px;
  }
  
  .register-tournament {
    padding: 15px;
  }

  .registeredStrip {
    grid-template-columns: 1fr;
    margin: 5px 0;
    padding: 0;
  }
}

@media screen and (max-width: 640px) {
  .base {
    padding: 90px 10px 20px; /* Réduire le padding latéral */
  }

  .registeredStrip {
    grid-template-columns: 1fr;
    margin: 5px 0;
    padding: 0;
  }

  .registeredContainer {
    margin: 0;
    padding: 0 5px;
  }

  .registeredTournament {
    flex: 0 0 150px; /* Réduire encore la taille pour les très petits écrans */
    height: 100px;
  }

  .scrollButton {
    width: 28px;
    height: 28px;
    padding: 4px;
  }
}