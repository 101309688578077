.notificationButton {
  position: fixed;
  right: 245px;
  top: 21px;
  padding: 7px;
  margin: 14px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  transition: transform 0.3s ease, background-color 0.3s ease;
  z-index: 11;
}

.notificationButton:hover {
  transform: scale(1.1);
}

.invitationButton:focus {
  outline: none;
}

.invitationSchema {
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
  font-size: 16px;
  background-color: #1f1f1f48;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  max-height: 250px;
  overflow: auto;
}

.invitationButton {
  padding: 8px 18px;
  margin: 15px;
  font-size: 16px;
  background-color: #2b2b2b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.invitationButton:hover {
  background-color: #353535;
}

.notificationModal {
  position: fixed;
  width: 500px;
  min-height: 250px;
  max-height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff1a;
  color: rgb(206, 211, 216);
  border-radius: 15px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.363);
  backdrop-filter: blur(12px);
  z-index: 1000;
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: 0px;
  background-color: #e74d3cd8;
  color: rgb(255, 255, 255);
  border-radius: 50%;
  padding: 1px 6px;
  font-size: 10px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.notificationBadge.new {
  animation: pulse 1s infinite;
}

@media screen and (max-width: 820px) {
 .notificationButton {
  display: none;
 }
}