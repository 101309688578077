.tournamentsList {
  text-align: center;
  color: white;
  margin: 30px auto; /* Ajout de auto pour centrer */
  width: 95%; /* Prendre presque toute la largeur disponible */
}

.tournamentsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.registeredStrip {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 25fr 1fr;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  overflow: hidden; /* Important */
}

.registeredContainer {
  /* On garde le flex mais on ajoute nowrap pour forcer le comportement carousel */
  display: flex;
  flex-wrap: nowrap;
  gap: 15px;
  border-radius: 10px;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 5px;
}

.registeredContainer::-webkit-scrollbar {
  display: none;
}

.registeredTournament {
  flex: 0 0 280px;
  height: 150px;
  position: relative;
}

.registeredTournamentInner {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}

.registeredOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.registeredContent {
  position: relative;
  z-index: 2;
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.registeredContent h3 {
  color: white;
  margin: 0;
  font-size: 0.9rem;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
}

.registeredActions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.scrollButton {
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
}

.scrollButton:first-child {
  left: 5px;
}

.scrollButton:last-child {
  right: 5px;
}

.scrollButton:hover {
  transform: scale(1.1);
}

.tournamentItem {
  flex: 1 1 calc(45% - 20px); /* Réduit légèrement pour tenir compte du gap */
  max-width: calc(50% - 20px);
  margin: 10px;
  padding: 10px;
  border-radius: 15px;
  background-color: #222;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  position: relative;
  color: white;
  overflow: hidden;
}

.bracketOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.bracketContent {
  background-color: rgba(0, 0, 0, 0.185);
  box-shadow: 0px 0px 10px #ffffff10;
  backdrop-filter: blur(30px);
  padding: 20px;
  border-radius: 15px;
  position: relative;
  width: 60%;
  color: white;
}

@media screen and (min-width: 850px) and (max-width: 1400px) {
  .bracketContent {
    width: 70%;
  }
}

@media screen and (max-width: 850px) {
  .bracketContent {
    width: 80%;
  }
}

.closeBracketButton {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.closeBracketButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.tournamentItem::before {
  content: '';
  display: block;
  padding-top: 56.25%;
}

.tournamentItem:hover {
  content: '';
  display: block;
  box-shadow: 0 0px 15px rgba(255, 255, 255, 0.4);
  transition: 0.2s ease;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.tournamentTitle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  padding: 20px;
  border-radius: 10px;
  z-index: 2;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
}

.tournamentInfo h2 {
  margin-bottom: 10px;
  text-align: center;
}

.tournamentInfo p {
  margin: 10px 0;
}

.entryFee {
  position: absolute;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
  bottom: 20px;
  right: 20px;
  border-radius: 5px;
  color: rgb(216, 216, 216);
}

.entryFeeButton,
.bracketButton {
  min-width: 80px;
  height: 36px;
  padding: 0 16px;
  border-radius: 6px;
  border: none;
  background-color: rgba(56, 55, 55, 0.8);
  color: rgb(216, 216, 216);
  font-weight: 600;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.entryFeeButton:hover,
.bracketButton:hover {
  background-color: rgba(85, 84, 84, 0.8);
  transform: translateY(-2px);
}

.seed {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  border: none;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
  background-color: #383737da;
  cursor: pointer;
}

.seed:hover {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.7);
  background-color: #6d6c6ccb;
  transition: 0.3s ease;
}

.copySeed {
  position: absolute;
  width: 27px;
  height: 27px;
  top: 50%;
  left: 50%;
  transform: translate(-48.5%, -44%);
}

@media screen and (max-width: 1400px) {
  .tournamentItem {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(80% - 20px);
  }
}

@media screen and (max-width: 820px) {
  .tournamentsList {
    margin: 0;
    width: 100%;
  }
  .tournamentItem {
    flex: 1 1 100%;
    max-width: 100%;
    margin: 10px 0;
  }
  
  .tournamentTitle {
    padding: 15px;
  }
  
  .bracketButton,
  .entryFeeButton {
    width: 70px;
    height: 28px;
    font-size: 14px;
  }
  
  .seed {
    width: 36px;
    height: 36px;
  }
  
  .copySeed {
    width: 24px;
    height: 24px;
  }
}

/* Desktop breakpoint */
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .registeredTournament {
    flex: 0 0 240px;
    height: 130px;
  }

  .registeredContent {
    padding: 12px;
  }

  .registeredContent h3 {
    font-size: 0.85rem;
  }
}

@media screen and (min-width: 820px) and (max-width: 1200px) {
  .registeredTournament {
    flex: 0 0 240px;
    height: 130px;
  }

  .registeredContent {
    padding: 12px;
  }

  .registeredContent h3 {
    font-size: 0.85rem;
  }
}

/* Mobile breakpoint */
@media screen and (max-width: 820px) {
  .registeredStrip {
    width: 100%;
    margin: 10px auto;
  }

  .registeredTournament {
    flex: 0 0 200px;
    height: 120px;
  }

  .registeredContainer {
    gap: 10px;
  }

  .registeredContent {
    padding: 10px;
  }

  .registeredContent h3 {
    font-size: 0.8rem;
  }

  .scrollButton {
    width: 32px;
    height: 32px;
  }

  .registeredActions {
    gap: 8px;
  }
}