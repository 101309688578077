.logoLinkedin {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-left: 5px;
}
.memberSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}
.teamMember {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  color: #212e3b;
  padding: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 200px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.containerWhitepaper {
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  min-height: 100vh;
  position: relative;
  z-index: 0;
}
.contentWhitepaper {
  flex: 1;
  padding: 40px;
  color: rgba(255, 255, 255, 0.836);
  background: linear-gradient(135deg, rgba(108, 99, 255, 0.1), rgba(255, 101, 132, 0.1));
  backdrop-filter: blur(3px);
  border-radius: 12px;
  isolation: isolate;
  margin-right: clamp(10px, 2vw, 20px);
}

/* Medium screens (tablets) */
@media (max-width: 1024px) {
  .containerWhitepaper {
    padding: 15px;
  }
  
  .contentWhitepaper {
    padding: clamp(20px, 4vw, 40px);
  }

  .sidebarWhitepaper {
    display: none;
  }
}

/* Small screens (mobile) */
@media (max-width: 768px) {
  .containerWhitepaper {
    flex-direction: column;
    padding: 10px;
  }

  .contentWhitepaper {
    margin: 0;
    padding: 20px;
    border-radius: 8px;
  }

  .sidebarWhitepaper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    max-height: 60vh;
    z-index: 1000;
    transform: translateY(calc(100% - 50px));
    transition: transform 0.3s ease-in-out;
    border-radius: 20px 20px 0 0;
    padding: 15px;
  }

  .sidebarWhitepaper:hover,
  .sidebarWhitepaper:focus {
    transform: translateY(0);
  }

  .sidebarWhitepaper::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 4px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
  }

  .teamMember {
    width: 100%;
  }

  /* Adjust card grid for mobile */
  .containerWhitepaper .card {
    padding: 15px;
  }
}

/* Extra small screens */
@media (max-width: 480px) {
  .containerWhitepaper {
    padding: 5px;
  }

  .contentWhitepaper {
    padding: 15px;
  }

  .containerWhitepaper .grid {
    grid-template-columns: 1fr;
  }
}

/* Print styles */
@media print {
  .sidebarWhitepaper {
    display: none;
  }

  .contentWhitepaper {
    padding: 0;
    box-shadow: none;
  }
}

.sidebarWhitepaper {
  width: 295px;
  padding: 20px;
  background: linear-gradient(135deg, rgba(108, 99, 255, 0.1), rgba(255, 101, 132, 0.1));
  backdrop-filter: blur(5px);
  color: white;
  position: sticky;
  top: 115px; 
  height: auto;
  border-radius: 12px;
  align-self: flex-start;
}
.containerWhitepaper h1,
.containerWhitepaper h2,
.containerWhitepaper h3,
.containerWhitepaper h4 {
  font-family: "Montserrat", sans-serif;
  color: #94A3B8 !important;
}
.containerWhitepaper h1 {
  font-size: 3.5em;
  color: white;
  margin-bottom: 30px;
  text-align: center;
  letter-spacing: -1px;
}
.containerWhitepaper h2 {
  font-size: 2em;
  color: var(--primary);
  font-weight: bold;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 3px solid rgba(255, 101, 132, 0.6);
}
.containerWhitepaper h3 {
  font-size: 1.4em;
  color: #cad1d8;
  margin-top: 30px;
  margin-bottom: 15px;
}
.containerWhitepaper h4 {
  font-size: 1.2em;
  color: var(--primary);
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  display: flex;
  padding-bottom: 30px;
}
.containerWhitepaper p,
.containerWhitepaper ul,
.containerWhitepaper ol {
  margin-bottom: 20px;
  font-size: 1.05em;
  text-align: justify;
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  hyphenate-character: "-"; /* caractère de césure */
  hyphens: auto; /* active la césure automatique */
}
.containerWhitepaper .highlight {
  background: linear-gradient(135deg, rgba(108, 99, 255, 0.1), rgba(255, 101, 132, 0.1));
  color: #7ba2cf;
  padding: 2px 5px;
  border-radius: 3px;
  font-weight: 600;
}
.sidebarWhitepaper h2 {
  font-size: 1.6em;
  color: white;
  margin-bottom: 25px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 15px;
}
.sidebarWhitepaper ul {
  list-style-type: none;
}
.sidebarWhitepaper li {
  margin-bottom: 15px;
}
.sidebarWhitepaper a {
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 1.1em;
  display: block;
  padding: 8px 12px;
  border-radius: 6px;
}
.sidebarWhitepaper a:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateX(5px);
}
.containerWhitepaper .imagePlaceholder {
  background: linear-gradient(135deg, rgba(108, 99, 255, 0.3), rgba(255, 101, 132, 0.3));
  backdrop-filter: blur(30px);
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  border-radius: 12px;
  color: #94A3B8;
  font-weight: 600;
  font-size: 1.2em;
  text-align: center;
  padding: 20px;
}
.containerWhitepaper .grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 25px;
  margin-bottom: 30px;
}
.containerWhitepaper .card {
  background-color: #f8fafc33;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.containerWhitepaper .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}
.containerWhitepaper .card h3 {
  color: #3498db;
  margin-top: 0;
}
.containerWhitepaper .button {
  display: inline-block;
  background: var(--gradient);
  color: white;
  padding: 12px 24px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  margin-top: 20px;
}
.containerWhitepaper .button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}
