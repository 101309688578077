.rondBizarreOrangeQuiClignote::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 80%;
  right: -20%;
  top: 10%;
  background: var(--gradient);
  filter: blur(120px);
  opacity: 0.2;
  animation: pulse2 4s ease-in-out infinite;
  z-index: 0;
}

.rondBizarreOrangeQuiClignote2::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 80%;
  right: -20%;
  top: 10%;
  background: linear-gradient(135deg, rgba(108, 99, 255), rgba(255, 101, 132));
  filter: blur(120px);
  opacity: 0.2;
  animation: pulse2 4s ease-in-out infinite;
  z-index: 0;
}

body.whitepaper-active .vertical-nav .menu-item .icon {
  color: #6387ff!important;
}

body.whitepaper-active .vertical-nav .menu-item-logout .icon {
  color: #5a5a5a69!important;
}

@keyframes pulse2 {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.teamButton {
  position: fixed;
  right: 205px;
  top: 35px;
  padding: 5px 6px;
  border: none;
  cursor: pointer;
  z-index: 11;
  transition: transform 0.3s ease;
  transition: background-color 0.3s ease;
}

.teamButton:hover {
  transform: scale(1.1);
}

.cta-button {
  position: fixed;
  right: 15px;
  top: 20px;
  background: var(--gradient);
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 50px;
  color: white;
  transition: transform 0.3s;
  z-index: 1000;
}

.cta-button:hover {
  transform: translateY(-2px);
}

.cta-button2 {
  position: fixed;
  right: 150px;
  top: 20px;
  background: var(--gradient);
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 50px;
  color: white;
  transition: transform 0.3s;
  z-index: 1000;
}

.cta-button2:hover {
  transform: translateY(-2px);
}

.copied-text {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  font-size: 13px;
  opacity: 0.7;
}

.copy-icon {
  width: 15px;
  height: 15px;
  margin-left: 3px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.copy-icon:hover {
  transform: scale(1.1);
}

.slide-in {
  animation: slide-in 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}

.slide-out {
  animation: slide-out 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.profil {
  position: fixed;
  z-index: 10;
  width: 300px;
  min-height: 77px;
  right: 22px;
  top: 22px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px #ffffff10;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  overflow: hidden;
  white-space: nowrap;
  transform: translateY(0);
}

.profil_info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 8px;
  width: 100%;
  height: 100%;
  color: rgba(216, 234, 252, 0.678);
  position: relative;
}

.profil_info_details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  line-height: 1.618;
  width: 100%;
}

.profil_title {
  width: 100%;
  padding: 5px;
  border-radius: 10px;
}

.profil_title p {
  margin: 0;
  display: flex;
  justify-content: end;
  align-items: flex-end;
}

.profil_title img {
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 0px 5px #2b2c2b;
}

.profil_title img:hover {
  transform: scale(1.1);
}

.profilsIcons {
  padding: 10px 0;
  color: #f4b000;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.profilsIcons:hover {
  transform: scale(1.1);
}

/* Twitch */
.stream iframe {
  border: none;
  border-radius: 40px;
  margin: 3px;
}

/* Style de la barre de recherche */
.searchBar {
  width: 258px;
  position: fixed;
  top: 22px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 15px;
  z-index: 1000;
}

/* Autres styles optionnels */
.searchBar input {
  display: flex;
  padding: 9px 30px;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: 0px 0px 10px #ffffff10;
  backdrop-filter: blur(12px);
  color: white;
  border-radius: 15px;
}

.searchBar input:focus {
  outline: none;
  background-color: #4e4e4eab;
}

.resultSearch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(12px);
  border-radius: 15px;
  padding: 10px 5px;
  max-height: 250px;
  top: 68px;
  width: 255px;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
}

.resultOverflow {
  overflow-y: auto;
}

.resultSearch li {
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.219);
  border-radius: 15px;
}

.resultSearch li:hover {
  background-color: rgba(255, 255, 255, 0.158);
  border-radius: 15px;
}

.resultItem .name {
  display: flex;
  align-items: start;
  font-weight: 700;
}

.resultSearch h3 {
  margin: 0;
  padding: 10px;
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: 700;
}

.preview {
  display: none;
  padding: 0;
  z-index: 1000;
  max-width: 300px;
}

.resultItem:hover .preview {
  display: block;
}

.vertical-nav:hover {
  width: 250px;
}

.vertical-nav a {
  padding: 8px;
  text-decoration: none;
  font-size: 22px;
  color: white;
  transition: 0.3s;
  display: flex;
}

.vertical-nav a:hover {
  color: #f1f1f1;
}

.vertical-nav .logo {
  padding: 8px 0 45px 0;
  text-align: center;
}

.vertical-nav .logo img {
  width: 65px;
  height: auto;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.vertical-nav .logo img:hover {
  transform: scale(1.1);
}

.menu-item {
  display: flex;
  margin: 0;
  align-items: center;
  flex-direction: row;
  width: 100%;
  transition: all 0.3s ease;
  position: relative;
  transform-style: preserve-3d;
  padding: 8px;
}

.menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  transform: translateX(10px) translateZ(20px);
}

.icon {
  margin-left: auto;
  margin-right: auto;
  transition: margin 0.3s ease;
}

.vertical-nav.expanded .icon {
  margin-left: 0;
  margin-right: 0;
}

.navbar-text {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 1rem;
  display: none;
  transition: opacity 0.3s ease;
  white-space: nowrap;
}

.vertical-nav.expanded .navbar-text {
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: margin 0.3s ease;
}

/* Effet de blur au hover sur desktop */
.main-content {
  transition: filter 0.5s ease-in-out, transform 0.5s ease;
}

.vertical-nav:hover ~ .main-content {
  filter: blur(12px);
  transform: scale(1.03);
}

.menu-item-logout {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: column; /* Les icônes et le texte sont alignés horizontalement */
  width: 100%;
  color: #fdfdfd;
  font-weight: 700;
  font-size: large;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.nav-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 82%;
}

.hidden {
  display: none;
}

.expand-icon {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
  z-index: 2;
  cursor: pointer;
}

.expand-icon.expanded {
  transform: rotate(180deg);
}

.floating-cards {
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 1rem;
}

.card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(12px);
  padding: 1.5rem;
  border-radius: 15px;
  animation: float 6s ease-in-out infinite;
}

.card:nth-child(2) {
  animation-delay: -2s;
}

.card:nth-child(3) {
  animation-delay: -4s;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

/* Style de base pour la navbar */
.vertical-nav {
  width: 80px;
  height: auto;
  position: fixed;
  left: 22px;
  top: 22px;
  bottom: 22px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px #ffffff10;
  backdrop-filter: blur(12px);
  overflow: visible;
  transition: width 0.5s;
  white-space: nowrap;
  z-index: 10;
}

/* Menu burger pour mobile */
.burger-menu {
  display: none;
  cursor: pointer;
  padding: 15px;
  color: #f4b000;
  z-index: 20;
  transition: transform 0.3s ease;
}

@media screen and (max-width: 1024px) {
  .searchBar {
    display: none;
  }
  .resultSearch {
    display: none;
  }
}

/* Styles pour la version mobile */
@media screen and (max-width: 820px) {
  .vertical-nav:hover {
    width: 100%; /* Garder ceci si nécessaire */
  }

  .vertical-nav {
    background-color: transparent;
    backdrop-filter: none;
  }

  /* Le reste de vos styles mobile... */
  .vertical-nav {
    width: 100vw;
    height: 60px;
    left: 0;
    top: 0;
    bottom: auto;
    margin: 0;
    border-radius: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    filter: none;
    background-color: transparent;
    box-shadow: none;
  }

  /* Désactivation explicite des effets desktop */
  .vertical-nav:hover {
    width: 100%;
    backdrop-filter: none;
  }

  .vertical-nav:hover ~ .main-content {
    filter: none;
    transform: none;
  }

  .cta-button {
    position: absolute;
    width: 100px;
    padding: 10px;
    top: 10px;
  }

  .cta-button2 {
    position: absolute;
    width: 100px;
    padding: 10px;
    top: 10px;
    right: 120px;
  }

  .App {
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    position: relative;
  }

  .main-content {
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    position: relative;
  }

  /* Garder la navigation nette */
  .vertical-nav,
  .nav-item.active {
    z-index: 100;
  }

  .burger-menu {
    display: block;
    font-size: 24px;
    z-index: 20;
  }

  .nav-item {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: auto;
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    display: none;
    z-index: 20;
  }

  .profil {
    z-index: 1000;
  }

  .copied-text {
    display: none;
  }

  /* Afficher le menu quand il est actif */
  .nav-item.active {
    display: flex;
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    height: auto;
    padding: 15px;
    flex-direction: column;
    width: 95%;
    margin: 2.5% 2.5% 2.5% 2.5%;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.05);
    box-shadow: 0 10px 10px #242424;
    backdrop-filter: blur(20px);
    z-index: 20;
  }

  /* Ajuster le logo */
  .vertical-nav .logo {
    padding: 0;
  }

  .vertical-nav .logo img {
    display: none;
  }

  /* Style pour les liens du menu en mobile */
  .menu-item {
    width: 100%;
    padding: 15px;
    margin: 5px 0;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.05);
    display: flex;
    align-items: center;
  }

  .nav-item > div {
    width: 100%; /* Force la pleine largeur pour les conteneurs enfants */
  }

  /* Alignement des icônes */
  .icon {
    margin: 0;
    width: 33px;
    display: flex;
    justify-content: center;
  }

  /* Texte du menu */
  .navbar-text {
    display: inline-block !important;
    margin-left: 15px;
    opacity: 1;
  }

  /* Ajustements pour le profil en mobile */
  .profil {
    position: fixed;
    top: 5px;
    right: 10px;
    width: auto;
    min-height: auto;
    background: none;
    box-shadow: none;
    transition: none;
    backdrop-filter: none;
  }

  /* Ajustements pour le profil en mobile */
  .profilExpanded {
    position: fixed;
    top: 5px;
    left: 10px;
    right: 10px;
    width: auto;
    min-height: auto;
    background: none;
    backdrop-filter: blur(12px);
  }

  .profil_info {
    padding: 0;
  }

  .profil_info img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  /* Cacher les éléments non nécessaires en mobile */
  .profil_title p,
  .profil_info_details,
  .teamButton {
    display: none;
  }

  .mobileChevronDown {
    width: 43px;
    height: 43px;
    border-radius: 50px;
    margin-top: 0;
  }

  /* Override des styles de hover de la navbar */
  .vertical-nav:hover .navbar-text,
  .vertical-nav.expanded .navbar-text {
    opacity: 1;
    display: flex;
  }

  /* Correction de l'alignement des icônes */
  .icon {
    margin-left: 0 !important; /* Supprime la marge auto */
    margin-right: 0 !important;
    width: 33px; /* Largeur fixe pour l'alignement */
  }

  /* Ajustement du conteneur du menu */
  .nav-item {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligne tout à gauche */
  }

  .menu-item:hover {
    transform: none !important;
    box-shadow: none !important;
  }

  .menu-item-logout {
    display: block;
    margin: 0;
    padding: 0;
  }

  /* Animation du menu */
  .nav-item {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    opacity: 0;
  }

  .nav-item.active {
    transform: translateY(0);
    opacity: 1;
  }

  .searchBar {
    display: none;
  }
}

/* Animation du burger */
.burger-menu .fa-times {
  animation: rotateIn 0.3s ease-in-out;
}

.burger-menu .fa-bars {
  animation: rotateOut 0.3s ease-in-out;
}

@keyframes rotateIn {
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes rotateOut {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@media (max-width: 768px) {
  .hero {
    text-align: center;
  }

  .nav-links {
    display: none;
  }

  .floating-cards {
    display: none;
  }

  .title {
    font-size: 3rem;
  }
}

@media (min-width: 820px && max-width: 1383px) {
  .mainContentHome {
    padding-left: 125px;
  }
}