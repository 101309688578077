.tournament-bracket {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 2rem 2rem;
  gap: 5rem;
  min-height: auto;
}

.round {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around !important;
  min-width: 15rem;
}

.round-title {
  color: #ffffff;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.match {
  background-color: transparent;
  border-radius: 8px;
  margin: 0.5rem 0;
  width: 95%;
  position: relative;
  transition: transform 0.2s ease;
}

.team-container {
  background-color: #2a2a2a;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #333;
}

.team {
  padding: 0.71rem 1.5rem;
  color: #ffffff;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.95rem;
  transition: background-color 0.2s ease;
}

.team:hover {
  background-color: #333;
}

.team.winner {
  background-color: #3c8a40;
  position: relative;
}

.team.winner::after {
  color: #4CAF50;
}

.vs {
  text-align: center;
  color: #666;
  padding: 0.5rem;
  background-color: #222;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.tbd {
  color: #666;
}

/* Nouveaux styles pour les connecteurs */
.connector-container {
  position: absolute;
  right: -5rem;
  width: 5rem;
  pointer-events: none;
}

.connector-top {
  top: 0;
  height: 50%;
}

.connector-bottom {
  bottom: 0;
  height: 50%;
}

.connector-line {
  position: absolute;
  background-color: #333;
  transition: all 0.3s ease;
}

.connector-line.winner {
  background-color: #3c8a40;
}

.connector-horizontal {
  width: 100%;
  height: 2px;
  left: 0;
}

.connector-vertical {
  width: 2px;
  right: 0;
  height: 90%;
}

.connector-top .connector-horizontal {
  bottom: 0;
}

.connector-bottom .connector-horizontal {
  top: 0;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .tournament-bracket {
    padding: 1rem 2rem;
    gap: 3rem;
  }

  .round {
    min-width: 12rem;
  }

  .connector-container {
    right: -5rem;
    width: 5rem;
  }
}

/* Responsive adjustments */
@media screen and (min-width: 1057px) and (max-width: 1200px) {
  .tournament-bracket {
    padding: 1rem 2rem;
    gap: 3rem;
  }

  .round {
    min-width: 10rem;
  }

  .connector-container {
    right: -5rem;
    width: 5rem;
  }
}

@media screen and (min-width: 738px) and (max-width: 1057px) {
  .tournament-bracket {
    padding: 1rem 2rem;
    gap: 3rem;
  }

  .round {
    min-width: 8rem;
  }

  .connector-container {
    right: -5rem;
    width: 5rem;
  }
}

@media screen and (min-width: 850px) and (max-width: 925px) {
  .tournament-bracket {
    overflow-x: auto;
    padding: 1rem;
    gap: 2rem;
  }

  .round {
    min-width: 7rem;
  }

  .team {
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 850px) {
  .tournament-bracket {
    overflow-x: auto;
    padding: 1rem;
    gap: 2rem;
  }

  .round {
    min-width: 120px;
  }

  .team {
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
  }
}